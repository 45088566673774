// Render which product categories and filters and sorts shoudl be applied to each category
export function determineCategoryFilters(productCategory) {
  const productCategoryToFilter = {
    all: {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": [],
      },
      filters: {
        "Product Type": ["Aluminum", "Refills", "Everyday Essentials"],
        "Product Category": [
          "Liquid Hand Soap",
          "Foaming Hand Soap",
          "Laundry",
          "Cleaning Sprays",
          "Dish Soap",
          "Dish Tablets",
          "Toilet Cleaner",
          // "Bar Soap",
          "Gift Cards",
        ],
      },
    },
    bundles: {
      preDefinedFilters: {
        "Product Type": ["Bundles"],
        "Product Category": [],
      },
      filters: {
        "Product Type": null,
        "Product Category": [
          "Liquid Hand Soap",
          "Foaming Hand Soap",
          "Laundry",
          "Cleaning Sprays",
          "Dish Soap",
          "Dish Tablets",
          // "Bar Soap",
        ],
      },
    },
    refills: {
      preDefinedFilters: {
        "Product Type": ["Refills"],
        "Product Category": [],
      },
      filters: {
        "Product Type": null,
        "Product Category": [
          "Liquid Hand Soap",
          "Foaming Hand Soap",
          "Laundry",
          "Cleaning Sprays",
          "Dish Soap",
          "Dish Tablets",
        ],
      },
    },

    laundry: {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": ["Laundry"],
      },
      filters: {
        "Product Type": ["Refills"],
        "Product Category": null,
      },
    },
    "toilet-cleaner": {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": ["Toilet Cleaner"],
      },
      filters: {
        "Product Type": ["Everyday Essentials"],
        "Product Category": null,
      },
    },
    "hand-soaps": {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": ["Liquid Hand Soap", "Foaming Hand Soap"],
      },
      filters: {
        "Product Type": ["Aluminum", "Refills"],
        "Product Category": ["Liquid Hand Soap", "Foaming Hand Soap"],
      },
    },
    "cleaning-sprays": {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": ["Cleaning Sprays"],
      },
      filters: {
        "Product Type": ["Aluminum", "Refills"],
        "Product Category": null,
      },
    },
    dish: {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": ["Dish Soap", "Dish Tablets"],
      },
      filters: {
        "Product Type": ["Aluminum", "Refills"],
        "Product Category": ["Dish Soap", "Dish Tablets"],
      },
    },
    "bar-soap": {
      preDefinedFilters: {
        "Product Type": [],
        "Product Category": ["Bar S oap"],
      },
      filters: {
        "Product Type": null,
        "Product Category": null,
      },
    },
  };

  let productCategoryFilters = productCategoryToFilter[productCategory];
  productCategoryFilters.filters["Sort"] = ["Price: High To Low", "Price: Low To High"];
  return productCategoryFilters;
}

export function filterProductInventory(activefiltersArray, preDefinedFilters, productInventory) {
  // clonse active filters array
  activefiltersArray = JSON.parse(JSON.stringify(activefiltersArray));

  // PREDFINED FILTERS ARRAY
  if (activefiltersArray["Product Type"].includes("Aluminum")) {
    activefiltersArray["Product Type"].push("Aluminum Filled");
  }

  if (activefiltersArray["Product Type"].includes("Everyday Essentials")) {
    activefiltersArray["Product Type"].push("essentials");
    activefiltersArray["Product Type"].push("Essentials");
  }
  if (preDefinedFilters["Product Type"].length > 0) {
    productInventory = productInventory.filter(function (productObject) {
      let inFilter = false;
      productObject.variation.shopAllFilters.map(function (productFilter) {
        if (preDefinedFilters["Product Type"].includes(productFilter)) {
          inFilter = true;
        }
      });
      return inFilter;
    });
  }
  if (preDefinedFilters["Product Category"].length > 0) {
    productInventory = productInventory.filter(function (productObject) {
      let inFilter = false;
      productObject.variation.shopAllFilters.map(function (productFilter) {
        if (preDefinedFilters["Product Category"].includes(productFilter)) {
          inFilter = true;
        }
      });
      return inFilter;
    });
  }

  // ACTIVE FILTER ARRAY
  if (activefiltersArray["Product Type"].length > 0) {
    productInventory = productInventory.filter(function (productObject) {
      let inFilter = false;
      productObject.variation.shopAllFilters.map(function (productFilter) {
        if (activefiltersArray["Product Type"].includes(productFilter)) {
          inFilter = true;
        }
      });
      return inFilter;
    });
  }
  if (activefiltersArray["Product Category"].length > 0) {
    productInventory = productInventory.filter(function (productObject) {
      let inFilter = false;
      productObject.variation.shopAllFilters.map(function (productFilter) {
        if (activefiltersArray["Product Category"].includes(productFilter)) {
          inFilter = true;
        }
      });
      return inFilter;
    });
  }

  productInventory = productInventory.filter(function (productObject) {
    if (productObject.hideOnSite) {
      return false;
    }

    if (productObject.variation.productCategory === "bundles") {
      if (
        preDefinedFilters["Product Type"].includes("Bundles") ||
        (preDefinedFilters["Product Type"].length == 0 && preDefinedFilters["Product Category"].length === 0)
      ) {
        if (productObject.leadBundle) {
          return true;
        } else {
          return false;
        }
      } else {
        if (productObject.leadBundle) {
          return false;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  });

  return productInventory;
}

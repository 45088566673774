// Mail Check Stuff
export const domains = [
    "gmail.com", 
    "aol.com", 
    "yahoo.com", 
    "msn.com",
    "apple.com",
    "hotmail.com"
];

export const secondLevelDomains = [
    "hotmail"
];

export const topLevelDomains = [
    "com",
    "net",
    "org",
    "io"
];
import axios from 'axios'
import { toast } from 'react-toastify'

// Posts user email to klaviyo and loads it into local storage
// Email is used later to place into various klayio scripts
export const addEmailToNewsLetter = async (email) => {

    // Add email to local storage for later retreival for mixpanel and klaviyo scripts
    localStorage.setItem('analyticsEmail', email);
    
    return await axios({
        method: "post",
        url: "/api/auth/sign-up-for-newsletter",
        data: {
            email: email,
        }
    }).then(({ data }) => {
        toast.success("Successfully added you to the mailing list!")
        return data
    }).catch(({response}) => {
        return response.data.error
        return null
    })


}
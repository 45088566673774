import React from 'react'
import { Form, Field } from 'react-final-form'
import Button from "@common/buttons/Button";
import { emailIsValid, addEmailToNewsLetter } from "@helpers";
import {PlainTextField} from "@fields";
import styled from "styled-components";

// INline form can be overridden to all sepetate the button the form
const EmailCaptureForm = styled.form`
    display: ${props => props.overRideInline ? "block" : "flex"};
    & input, div {
        border-top-right-radius: ${props => props.overRideInline ? props.theme.borderRadius : "0px"};
        border-bottom-right-radius: ${props => props.overRideInline ? props.theme.borderRadius : "0px"};
        border-right: ${props => props.overRideInline ? "" : "none"};
        flex: 4;
    }

    & button {
        flex: 1;
        height: auto;
        margin-top: ${props => props.overRideInline ? "10px" : "0px;"};
        border-top-left-radius: ${props => props.overRideInline ? "" : "0px;"};
        border-bottom-left-radius: ${props => props.overRideInline ? "" : "0px;"};
        display: block;
        
    }
`

// Small inline email capture form for the footer & instagram sections
// Can also be placed in blog posts
class InlineEmailCapture extends React.Component {
    
    // Submit the email to our mailing list end piont
    // The source is passed down the from the parent component indicating the placement of the form
    onSubmit = async (values) => {
        const email = values["email"]
        await addEmailToNewsLetter(email)
    }

    render() {
        const {
            source,
            overRideInline,
            dontClearForm,
            additionalLoading,
            submitButtonTextOverRide,
            successCallBack
        } = this.props;

        return (

            <Form
                onSubmit={this.onSubmit}
                initialValues={{
                    source: source
                }}
                validate={values => {
                    const errors = {}
                    if (!values.email) {
                        errors.email = 'Required'
                    }
                    if (values.email) {
                        if (!emailIsValid(values.email)) {
                            errors.email = "Please enter a valid email"
                        }
                    }
                    return errors
                }}
                render={({ handleSubmit, pristine, invalid, submitting, form }) => (
                    <fieldset>
                        <legend>Newsletter Form</legend>
                        <EmailCaptureForm overRideInline={overRideInline} onSubmit={event => {
                                handleSubmit(event).then(() => {
                                    
                                    // If not don't clear form, then clear teh form
                                    if (!dontClearForm) {
                                        form.setConfig('keepDirtyOnReinitialize', false);
                                        form.reset();
                                        form.setConfig('keepDirtyOnReinitialize', true);
                                    }
                                    if (successCallBack) {
                                        successCallBack()
                                    }
                                });
                            }}>
                            <Field
                                name="email"
                                component={PlainTextField} 
                                htmlType="email"
                                autoComplete="email"
                                label="Email Address"
                                required={true}
                                hideErrorText={true}
                            />
                            <Field
                                name="source"
                                component="input"
                                type="hidden"
                            />                        
                            <Button
                                type="submit"
                                loading={submitting || additionalLoading}
                                modifiers={pristine || invalid ? ["medium", "disabled"] : ["medium", "primary"]}
                            >
                                {
                                    submitButtonTextOverRide ? (
                                        submitButtonTextOverRide
                                    ) : (
                                        "Submit"
                                    )
                                }
                            </Button>
                        </EmailCaptureForm>
                    </fieldset>
                )}
            />
        )
    }
}


export default InlineEmailCapture
// Retrieves the properly formated META Image for the page
export function formatMetaImage(imageObject, pureImageURL) {
    let imageUrl;

    if (pureImageURL) {
        imageUrl = imageObject;
    } else {
        imageUrl = imageObject.fields.file.url;
    }

    var formattedURL = "https:" + imageUrl+  "?q=90&fl=progressive&w=961&fit=fill"
    return formattedURL
}
import React from "react";
import styled from "styled-components";
import InlineEmailCapture from "@forms/common/InlineEmailCapture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
  faTiktok,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Image from "next/image";

const SocialContactContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    order: -1;
  }
`;
const ColumnTitle = styled.p`
  color: #fff;
  font-family: ${(props) => props.theme.fonts.headline};
  font-weight: 700;
  font-size: 4.7rem;
  text-transform: uppercase;
  margin-bottom: -33px;
  width: fit-content;
  margin-top: 10px;
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    margin-top: 30px;
    margin-bottom: -30px;
  }
`;

const ContactInfoContainer = styled.div`
  & a {
    line-height: 39px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.white};
    font-weight: normal;
    color: white;
    font-size: 1.75rem;
    font-weight: 500;
    opacity: 0.8;
    transition: ${(props) => props.theme.transitions.standard};
    :hover {
      opacity: 1;
    }
  }
`;

const EmailCaptureContainer = styled.div`
  margin-bottom: 40px;
  margin-top: 40px;
  width: 400px;
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    width: 100%;
  }
`;
const ShareIconContainer = styled.div`
  max-width: 500px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.info};
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;

const ShareIcon = styled.div`
  cursor: pointer;

  color: white !important;
  font-size: 20px;
  color: ${(props) => props.theme.colors.black};
  margin-right: 0;
  padding: 5px 8px;
  border-radius: 100%;
  background-color: white;
  & svg {
    color: ${(props) => props.theme.colors.primary};
  }
  & a {
    color: ${(props) => props.theme.colors.black};
    transition: all 0.3s ease;
    :hover {
      color: ${(props) => props.theme.colors.primary};
      transition: all 0.3s ease;
    }
  }
`;

// Contains the social links as well as the email and phone number
const SocialAndContact = () => (
  <SocialContactContainer>
    <ColumnTitle>JOIN US </ColumnTitle>

    <EmailCaptureContainer>
      <InlineEmailCapture source="Footer Newsletter" />
    </EmailCaptureContainer>

    <ShareIconContainer>
      <ShareIcon>
        <a
          href="https://www.instagram.com/cleancult/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </ShareIcon>
      <ShareIcon>
        <a
          href="https://www.facebook.com/cleancult"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </ShareIcon>
      <ShareIcon>
        <a
          href="https://www.linkedin.com/company/cleancult"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </ShareIcon>
      <ShareIcon>
        <a
          href="https://www.tiktok.com/@jointhecleancult?lang=en"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTiktok} />
        </a>
      </ShareIcon>
    </ShareIconContainer>
  </SocialContactContainer>
);

export default SocialAndContact;
